
import { Container, Row, Col, Badge } from 'react-bootstrap';
import './WinnerSection.css'; // Import CSS file for styling
import FullLengthCard from '../FullLengthCard/FullLengthCard';

import { colors } from '../../colors.js';




const WinnerSection = () => {

const video = "https://www.youtube.com/embed/aODdhXIPITE"   



  return (
    <div>
      <Container fluid>
        {/* Centered Heading */}
        <Row className="justify-content-center">
          <Col xs="auto">
            <h1 className="section-heading">Winner of Škoda Kylaq</h1>
          </Col>
        </Row>


        <Row className="justify-content-center">
          <Col>
            <FullLengthCard content={<div> 
              Well played! We are as excited as you are that you are taking home Škoda Auto's first-ever Compact SUV.
              <Badge 
               pill 
               className=' w-100 d-flex align-items-center justify-content-center badge-name'
               style={{ height:'100px', textAlign:'center', fontSize:'1.5rem', marginTop:'20px' }}
              
              >
                <div>
                <span>Mohammed Ziyad</span>
              <br/>
              <span style={{fontSize:'1rem'}}>Kerala</span>

                </div>
               
              </Badge>


               </div>}/>
          </Col>
          </Row>

 {/* Horizontal Scrollable List of Thumbnails */}




       </Container>
       </div>
  );
};

export default WinnerSection;
