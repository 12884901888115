import React from 'react';
import { Navbar, Nav } from 'react-bootstrap';
import logo from '../../assets/skoda_logo.png'

const Navigation = () => {
  return (
    <Navbar expand="lg" variant="light" bg="white" style={{ backgroundColor: 'white', color: 'black', fontFamily: 'SKODANext-Bold, sans-serif', paddingLeft: '20px', paddingRight: '40px'}}>
     
        <Navbar.Brand href="/" style={{marginRight: 'auto !important'}}>
          <img
            src={logo}
            width="170"
            className="d-inline-block align-top"
            alt="Your brand logo"
          />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ml-auto" style={{"paddingLeft": "40px", fontFamily: 'SKODANext-Bold, sans-serif', color:"#161718", fontWeight:"400", fontSize:"1.2rem"}}>
            <Nav.Link href="/" style={{color:"#161718", textDecoration: 'none'}} >Home</Nav.Link>
            <Nav.Link href="/#/tncs" style={{color:"#161718", textDecoration: 'none'}} >T&Cs</Nav.Link>
          </Nav>
        </Navbar.Collapse>
    </Navbar>
  );
};

export default Navigation;
