import { Container, Row, Col, Card } from "react-bootstrap";

const Terms = () => {
  return (
    <>
      <Container fluid>
        {/* Centered Heading */}
        <Row className="justify-content-center">
          <Col xs="auto">
            <h1 className="section-heading">Terms and Conditions</h1>
          </Col>
        </Row>
      </Container>
      <Card className="full-length-card" style={{ marginTop: "0px" }}>
        <div style={{ fontSize: "0.9rem" }}>
          <p>
            <strong>
              <u>About the Contest:</u>
            </strong>
          </p>
          <p>
            The following terms and conditions govern the conduct of the contest
            (“Contest”) which are organised by Škoda Auto Volkswagen India Pvt.
            Ltd. (“SAVWIPL”).
          </p>
          <p>
            By participating in the Contest, each entrant fully and
            unconditionally agrees to accept these terms and conditions
            specified as herein below. If the Contestant (defined below) does
            not agree with these terms and conditions, Contestant is requested
            not to participate in the Contest.
          </p>
          <p>
            <strong>
              <u>Contest Duration:</u>
            </strong>
          </p>
          <p>
            The Contest start from February 27, 2024 at 12:00 PM and end at
            11:59 PM on April 12, 2024 IST (“Indian Standard Time”) (“Contest
            Duration”). However, SAVWIPL reserves the right to extend or shorten
            the Duration of the Contests at its sole discretion.
          </p>
          <p>
            <strong>
              <u>Definitions:</u>
            </strong>
          </p>
          <ol style={{ listStyleType: "lower-alpha" }}>
            <li>
              &quot;Contest&quot; means the contest titled as “Name Your Škoda
              Contest”
            </li>
            <li>
              &quot;Contestant&quot; means the Indian citizen residing in India
              and following the Instagram Channel or Twitter Profile or Facebook
              Page of Škoda Auto India or Instagram channel of Fans of Škoda or
              Twitter channel of Škoda India PR, who is eligible and chooses to
              participate in the Contest
            </li>
            <li>
              &quot;Judge&quot; or &quot;Judges&quot; means the Judge or the
              panel of judges appointed by SAVWIPL in its sole discretion, who
              shall have final authority to declare the winners of the Contests.
              The decision of Judge shall be final and binding on the
              Contestant.
            </li>
            <li>
              &quot;Prize&quot; means the prize declared for Winner (defined
              below) of the Contest by SAVWIPL.
            </li>
            <li>
              &quot;SAVWIPL” means Škoda Auto Volkswagen Pvt. Ltd., a company
              registered as per the Companies Act, 1956 and having its
              registered office at: E1, MIDC Industrial Area (Phase III),
              Village: Nigoje Mhalunge, Kharabwadi, Tal.: Khed, Chakan, Pune,
              Maharashtra 410501
            </li>
            <li>
              &quot;Winner / Winners&quot; mean the Contestant who is declared
              winner.
            </li>
          </ol>
          <p></p>
          <p>
            <strong>
              <u>Eligibility Criteria:</u>
            </strong>
          </p>
          <ol style={{ listStyleType: "lower-alpha" }}>
            <li>
              All Indian citizens residing in India are eligible to take part in
              the Contest.
            </li>
            <li>
              Participation subject to the respective state law. In case any
              contravention to the state laws the candidature of the
              participants shall deem to be void.
            </li>
            <li>
              Contestant must be 18 years or older to be eligible for taking
              part in Name Your Škoda Contest
            </li>
            <li>
              Entries received after the stipulated time will not be eligible.
            </li>
            <li>
              Employees of SAVWIPL, their affiliates, immediate families
              thereof, as well as employees of SAVWIPL’s advertising agencies,
              its auditors, executing agencies, business partners and their
              associate companies are eligible to participate.
            </li>
            <li>
              By applying to participate in the Contest, each Contestant
              represents that he/she has not been accused or convicted or is
              otherwise involved in any criminal offence and/or is not under
              inquiry or trial by the police or judiciary in his/her country of
              residence.
            </li>
          </ol>
          <p></p>
          <p>
            <strong>
              <u>Contest Requirements, Features and Procedures:</u>
            </strong>
          </p>
          <ol>
            <li>
              During the Contest Period, contestant must share their entries in
              the form of a story or post or comment on Instagram or Facebook or
              Twitter channels. Alternatively, the Contestant can visit the
              campaign website to share their entries.
            </li>
            <li>
              The entry is to be shared as per the Contest Phases, mentioned
              below:
              <ol style={{ listStyleType: "lower-alpha" }}>
                <li>
                  During the Phase 1 of the Contest, February 27, 2024 at 12:00
                  PM to 11:59 PM on April 12, 2024 IST (“Indian Standard Time”),
                  contestant must share a name for the car in order to be
                  eligible for the Contest
                </li>
                <li>
                  During the Phase 2 of the Contest Period, March 26, 2024 at
                  12:00 PM to 11:59 PM on April 12, 2024 IST (“Indian Standard
                  Time”), contestant must either share a new name for the car or
                  vote for the existing name which will be available on the
                  campaign website or published on Instagram or Facebook or
                  Twitter channels of Škoda Auto India, in order to be eligible
                  for the Contest
                </li>
              </ol>
            </li>
            <li>
              The Contest will be replicated across the following channels:
              <ol style={{ listStyleType: "lower-alpha" }}>
                <li>
                  <a href="https://www.instagram.com/skodaindia/">
                    https://www.instagram.com/skodaindia/
                  </a>
                </li>
                <li>
                  <a href="https://www.instagram.com/fansofskoda/">
                    https://www.instagram.com/fansofskoda/
                  </a>
                </li>
                <li>
                  <a href="https://www.facebook.com/skodaindia">
                    https://www.facebook.com/skodaindia
                  </a>
                </li>
                <li>
                  <a href="https://www.twitter.com/SkodaIndia/">
                    https://www.twitter.com/SkodaIndia/
                  </a>
                </li>
                <li>
                  <a href="https://twitter.com/skodaindia_pr">
                    https://twitter.com/skodaindia_pr
                  </a>
                </li>
                <li>
                  <a href="https://nameyourskoda.com">
                    https://nameyourskoda.com
                  </a>
                </li>
              </ol>
            </li>
            <li>
              The eligible person (‘Contestant’) will have to follow any of the
              above-mentioned social media channels to enter the Contest.
            </li>
            <li>
              To participate in the contest,
              <ol style={{ listStyleType: "lower-alpha" }}>
                <li>
                  Contestant must mention in their response hashtag
                  #NameYourSkoda
                </li>
                <li>
                  Contestant must fill in the form on the campaign website and
                  share their entry there.
                </li>
              </ol>
            </li>
          </ol>
          <p>
            <strong>
              <u>Participation Fees:</u>
            </strong>
          </p>
          <p>
            No subscription to SAVWIPL or purchase or payment of any fee is
            required for participating in the Contest. The participation is
            voluntary.
          </p>
          <p>
            <strong>
              <u>Prize: </u>
            </strong>
          </p>
          <ol>
            <li>
              The Winner(/s) shall be declared as per the below criteria and are
              eligible for the prize(/s) accordingly:
              <ol style={{ listStyleType: "lower-alpha" }}>
                <li>
                  If the name suggested by the Winning Participant is
                  shortlisted and finalised for the new car launching in Q1
                  2025, a maximum of 1 Winner will win the new car on the day of
                  its launch. The prize will also include one-year valid
                  Insurance and an active Service Maintenance Package. All the
                  additional charges including but not limited to taxes as per
                  the laws of Indian Government, RTO charges, FASTag and HSRP
                  Frame charges shall be borne by the winner. No other active
                  exchange offer shall be valid on this prize. Winner will be
                  declared as the first owner of the car.
                </li>
                <li>
                  If a pre-registered name is shortlisted and finalised for the
                  new car launching in Q1 2025, there will be a maximum of 10
                  Winners for the Contest basis the engagement they generate and
                  internal jury’s discretion; each Winner will win a full paid
                  trip to the Škoda Auto Headquarters in Prague, Czech Republic.
                  The prize &amp; date of validity of the tickets will be at the
                  sole discretion of SAVWIPL.
                  <ol style={{ listStyleType: "lower-roman" }}>
                    <li>
                      A full paid trip means return flight tickets from the
                      nearest International airport which offers flights to/from
                      Prague, Czech Republic, hotel stay for 3 nights, 3 meals
                      per day and local transfers &amp; sight-seeing as per
                      pre-defined itinerary that shall be shared with the Winner
                      upon its announcement. All other charges over and above
                      this shall be borne by the Winner.
                    </li>
                  </ol>
                </li>
              </ol>
            </li>
            <li>
              The Contestant understands that mere participation in the Contest
              does not entitle the Contestant to win the Prize.
            </li>
            <li>
              Subsequent to the verification of eligible entries, the Winners
              will be selected:
              <ol style={{ listStyleType: "lower-alpha" }}>
                <li>By a panel of jury chosen by SAVWIPL</li>
                <li>Based on ‘Quality’ and ‘Uniqueness’ of the Entries</li>
                <li>
                  Engagement around the Contest
                  <ol style={{ listStyleType: "lower-roman" }}>
                    <li>Number of followers</li>
                    <li>
                      Number of posts / stories / comments published around the
                      contest
                    </li>
                  </ol>
                </li>
                <li>
                  Checking the authenticity of the Entries:
                  <ol style={{ listStyleType: "lower-roman" }}>
                    <li>
                      The Entries will also be verified via certain details and
                      by Internal Jury. Upon verification from the Internal
                      Jury, the Contestant will be declared as Winner
                    </li>
                  </ol>
                </li>
              </ol>
            </li>
            <li>
              The decision of SAVWIPL in this regard shall be final and binding.
            </li>
            <li>
            The Winners will be announced on all SAVWIPL social media platforms where the contest is conducted.
            </li>
            <li>
              The Prize is non-transferable and no cash alternatives will be
              permitted. Winner shall have to bear all incidental costs, if any,
              that may arise such as transportation costs for the fulfilment of
              the Prize. Winner must comply with all terms and conditions
              mentioned here.
            </li>
            <li>
              The Prize must be accepted on an ‘as is’ basis. Any charge over
              and above the stated Prize shall have to be borne by the Winners
              themselves including without limitation travelling, Prize
              collection/delivery charges etc.
            </li>
            <li>
              SAVWIPL also reserves the right to change the Prize at any time
              during the Contest.
            </li>
            <li>
              SAVWIPL holds the right to ask for identification proof in the
              form of a valid passport copy, latest /recent passport size
              profile picture, Aadhar card/voter’s ID or any other document to
              validate the personal identification and the address proof of the
              Winner. The details provided will be verified by SAVWIPL.
            </li>
            <li>
              Prize shall be subject to terms and conditions and compliance with
              all applicable statutory legislations/ processes /formalities as
              may be applicable to specific Prize.
            </li>
          </ol>
          <p>
            <strong> </strong>
          </p>
          <p>
            <strong>
              <u>Terms and conditions:</u>
            </strong>
          </p>
          <ul>
            <li>
              By participating in the Contest, Contestant accepts and agrees to
              be bound by these terms and conditions, including any amendments
              and modifications hereto. Contestant also specifically agrees to
              be bound by and subject to the terms and conditions of Twitter,
              Instagram and Facebook governing the Contest or participation of
              Contestant.
            </li>
            <li>
              Additional Travelling and Lodging expense will be borne by Winner
              itself
            </li>
            <li>
              This Contest will be open for the Contest Duration communicated in
              the Contest requirements in India only.
            </li>
            <li>
              Participation subject to the respective state law. In case any
              contravention to the state laws the candidature of the
              participants shall deem to be void.
            </li>
            <li>
              Entries submitted in any manner other than specified in the
              Contest requirements will be automatically disqualified.
            </li>
            <li>
              Contestant shall not use multiple accounts/ profiles to send
              entries in the Contest. Contestant using fake accounts to send
              entries will be automatically disqualified.
            </li>
            <li>
              Multiple entries by the same Contestant are allowed, however, the
              Contestant can be declared as winner only once.
            </li>
            <li>
              At the end of the Contest Duration, all entries received by
              SAVWIPL during Contest period will be considered for deciding the
              Winner.
            </li>
            <li>
              By entering the Contest, each Contestant agrees to the use, by
              SAVWIPL and its partners, in any manner, for any purpose, at any
              time and in any medium, their name, photograph and/or likeness,
              without compensation.
            </li>
          </ul>
          <p>
            <strong>
              <u>Other Terms and Conditions:</u>
            </strong>
          </p>

          <ol>
            <li>
              It is strictly prohibited to submit entries that violate the
              rights of any person or entity (including intellectual property
              rights, rights for privacy, etc.), contain expressions of hate,
              abuse, offensive images or conduct, contain obscenity,
              pornography, sexually explicit content, constitute defamation, or
              any material that could give rise to any civil or criminal
              liability under applicable law or regulations or that otherwise
              may be in conflict with these terms and conditions.
            </li>
            <li>
              SAVWIPL is authorized to remove any entry that it does not see fit
              in the spirit of the campaign. Any irrelevant, spam, porn, or
              otherwise improper submission can be taken off without warning and
              explanation.
            </li>
            <li>
              By participating in the Contest, each Contestant grants (with
              regard to a copyright included in the entry submitted), or
              transfers (with regard to any other rights in the entry
              submitted), to the SAVWIPL or its affiliates (including but not
              limited to Škoda Auto a.s., with its registered office at tř.
              Václava Klementa 869, Mladá Boleslav II, 293 01 Mladá Boleslav,
              Czech Republic) a perpetual, exclusive, irrevocable, worldwide,
              royalty-free, sub-licensable and transferable right to use,
              register as a trademark or a copyright, copy, print, display,
              prepare derivative works or names of, reproduce, adapt, modify,
              publish, post, broadcast, transmit and distribute the entry
              submitted, in any manner it deems fit, for any purpose, including
              for Contest, production, marketing, distribution, sales of
              vehicles and other publicity purposes in any media formats and
              through any media channels, without notification or approval, all
              without any additional compensation to Contestant and/or Winners.
              <br/>
              SAVWIPL or its affiliates hereby accepts the above mentioned
              rights and is entitled, but not obliged, to use the entry
              submitted as specified above.
            </li>
            <li>
              The distribution of Prize shall be at the sole discretion of
              SAVWIPL. SAVWIPL may substitute or change Prize offered under
              Contest at any time without notice. Contestant/Winner may not
              substitute Prize for other items or exchange for cash. Prize shall
              be non-transferable.
            </li>
            <li>
              SAVWIPL will contact the Winners via the email ID provided by
              them. SAVWIPL may require the Winner to furnish further
              information viz., contact address, identity proof, pan card
              details, phone number, etc. Identification considered suitable for
              verification and subsequent dispatch of Prize is at the sole
              discretion of SAVWIPL.
            </li>
            <li>
              By participating in Contest, each Contestant agrees that
              Contestant’s name, address, telephone numbers, e-mail identities
              or any other information that is provided by the Contestant
              (“Personal Information”) may be shared by SAVWIPL with others
              associated with and/or assisting in organizing and administering
              Contest, and to send to the Contestant Personal information
              pertaining to the SAVWIPL, in the future.
            </li>
            <li>
              SAVWIPL shall not be liable to dispatch the Prize in case it is
              unable to contact the Winner despite making reasonable efforts or
              in case the Winner fails to revert to SAVWIPL within a reasonable
              period of time. In such situation, prize won by the winner shall
              automatically stand cancelled and winner shall have no right to
              claim the prize in future. In such an event, SAVWIPL shall in its
              sole and absolute discretion be entitled to select an alternate
              Winner.
            </li>
            <li>
              SAVWIPL shall not be liable for any damage/loss/non-delivery of
              Prize due to incorrect address or any other incorrect information
              provided by the Winner.
            </li>
            <li>
              SAVWIPL shall not be responsible for any cost, expense or other
              liability whatsoever in relation to, arising from or connected
              with Prize or its quality. SAVWIPL shall not be responsible for
              any injury (including death), loss, damage or other consequence
              whatsoever arising from, suffered or incurred by Winners from or
              in relation to Prize. SAVWIPL assumes no responsibility for
              non-receipt of Prize by the Winners or for any damage, loss,
              injury or disappointment suffered by any Contestant/Winner
              participating in Contest or as a result of accepting any Prize.
            </li>
            <li>
              The Prize images used in the Contest advertisements / Contest
              materials (if any) are only illustrative in nature and the actual
              Prize may differ from the illustrative image.
            </li>
            <li>
              If for any reason the Contest cannot be held as planned, including
              without limitation any infection by computer virus, bugs,
              tampering, unauthorized intervention, fraud, technical failures,
              or any other causes which corrupt or affect the administration,
              security, fairness, integrity, or proper conduct of the Contest,
              or for any other reason whatsoever, SAVWIPL reserves the right, in
              its sole discretion, to cancel, terminate, modify or suspend the
              Contest. Any failure by SAVWIPL to perform its obligations will be
              excused to the extent such performance is prevented due to reason
              of force majeure such as acts of God, explosion, labor dispute,
              failure of utilities, governmental interference or any other act
              or condition beyond the reasonable control of SAVWIPL. SAVWIPL
              shall not be liable for compensating for any financial loss
              arising out of such circumstances.
            </li>
            <li>
              Contestant shall be solely responsible for any costs and expenses
              incurred towards internet connection charges, e-mail transmission
              charges, data transfer (send/receive) charges, mobile connection
              charges or other incidental costs or expenses as may be
              applicable, inclusive or exclusive of any other service charges,
              applicable taxes, levies, duties, cess, etc. depending upon the
              service provider
            </li>
            <li>
              Mere participation in the Contest does not entitle the Contestant
              to receive a Prize. SAVWIPL reserves the sole and absolute right
              to disqualify any person from (i) his or her participation in the
              Contest, and (ii) receipt of any Prize and/or require the return
              of any such Prizes delivered. In such an event, SAVWIPL reserves
              the right to select a new Winner for the Prize and/or dispose of
              the Prize in any manner it deems fit, at its sole discretion.
            </li>
            <li>
              Contestant expressly agrees and acknowledges that Contestant uses
              the service at its sole risk and responsibility. That the Prize is
              provided on an &quot;AS IS&quot; and &quot;AS AVAILABLE&quot;
              basis. SAVWIPL expressly disclaims all warranties of any kind,
              whether express or implied. Neither does SAVWIPL make any warranty
              that the Contest and or emanating results will meet your
              expectations, or the services will be uninterrupted, timely,
              secure, or error free.
            </li>
            <li>
              Under no circumstances shall SAVWIPL and/or its directors,
              employees, officers, affiliates or subsidiaries, be liable to the
              Contestant and/or any third party for any lost profits or lost
              opportunity, indirect, special, consequential, incidental, or
              punitive damages whatsoever, even if SAVWIPL has been advised of
              the possibility of such damages. Notwithstanding anything
              contained herein, the aggregate liability of the SAVWIPL to a
              Contestant for any reason whatsoever shall not exceed the value of
              one Prize.
            </li>
            <li>
              Contestant shall indemnify SAVWIPL, its agents, employees,
              representatives, associates, affiliates, parent and subsidiary
              companies, associates and partners against any and all claim,
              losses, costs, damages, liability and expenses arising out of the
              Contestant’s breach of any of these terms and conditions or for
              any reason whatsoever connected to or arising out of their
              participation in the Contest. To the extent permitted by
              applicable law, each Contestant, by entering the contest,
              expressly discharges and releases SAVWIPL from any claim, action
              or demand arising out of or in connection with the Contest or
              their prizes.
            </li>
            <li>
              SAVWIPL Employee can take part in the contest. Employees involved
              in the organization or execution of the contest must disclose any
              potential conflicts of interest and may be excluded from
              participating to ensure fairness. Employees are expected to engage
              in fair play and sportsmanship throughout the contest. Any attempt
              to manipulate results or engage in unfair practices may lead to
              disqualification.
            </li>
            <li>
              SAVWIPL does not make any commitment, express or implied, to
              respond to any feedback, suggestion and, or, queries of the
              Contestant or furnish any reason or explanation for inclusion and,
              or, exclusion of any particular submission or the entry of a
              Contestant at any stage of the Contest.
            </li>
            <li>
              Notwithstanding anything contained herein, the decision of SAVWIPL
              will be final and binding with regard to the Contest, declaration
              of Winners and prizes and no correspondence, objection,
              complaints, etc. will be entertained in this regard.
            </li>
            <li>
              The updated terms and conditions governing the Contest will be
              published by SAVWIPL at the same web link where the original terms
              and conditions are published. SAVWIPL also reserves the right to
              modify, cancel, extend and/or discontinue the Contest or any part
              thereof at any stage without assigning any reason and without
              giving any prior notice and without any liability. Contestants are
              advised to regularly check for any amendment(s) or update(s) to
              the terms and conditions No correspondence in this regard shall be
              entertained.
            </li>
            <li>
              The number of winners that will be selected will be at the sole
              discretion of SAVWIPL.
            </li>
            <li>
              For all purposes in relation to this Contest, the courts at Mumbai
              alone shall have exclusive jurisdiction. No other courts shall
              have jurisdiction to deal with any dispute or any matter between
              the Contestant or any other party and SAVWIPL arising out of this
              Contest.
            </li>
            <li>
              This terms and conditions of the present Contest shall be
              construed in accordance with and be governed by the Laws of India.
            </li>
          </ol>
        </div>
      </Card>
    </>
  );
};
export default Terms;
