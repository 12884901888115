


import { Row, Col } from 'react-bootstrap';

import banner from '../../assets/banner.jpg';

import { colors } from '../../colors.js';

const Banner = () => {



return(
  <img
  src={banner}
  alt="Name Your Skoda"
  className="img-fluid"
/>
)

}

export default Banner;