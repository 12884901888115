
import {Card} from 'react-bootstrap';
import "./FullLengthCard.css";

const FullLengthCard = (props) => {

    return (
        <Card className='full-length-card'>
            <Card.Text className='mx-auto'>
            {props.content}
            </Card.Text>
        </Card>
    )

}

export default FullLengthCard;