import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { FaFacebook, FaInstagram, FaXTwitter, FaYoutube } from 'react-icons/fa6';
import fosLogo from '../../assets/FoS Logo.png';
import './Footer.css'
const Footer = () => {
  return (
    <footer id="footer">
        <Row>
          <Col xs={12} sm={6}>
            <a href='/#/tncs'  className="t-and-cs" >*T&C Apply</a>
          </Col>
          <Col  className="footer-icons-column" xs={12}  sm={6} >
            <a href="https://www.facebook.com/SkodaIndia/"><FaFacebook color='white' size='24px' className='footer-icons'   /></a>
            <a href="https://twitter.com/SkodaIndia/"><FaXTwitter  color='white' size='24px' className='footer-icons'  /></a>
            <a href="https://www.instagram.com/skodaindia/"><FaInstagram  color='white' size='24px'className='footer-icons'  /></a>
            <a href="https://www.youtube.com/user/skodaindia"><FaYoutube  color='white' size='24px' className='footer-icons' /></a>
            <a href="https://www.instagram.com/fansofskoda/"><img src={fosLogo} height='24px' width='24px'  alt='Fans of Skoda'/></a>
          </Col>
        </Row>
    </footer>
  );
};

export default Footer;