import Banner from "./Banner";
import Navigation from "./Navigation";

const Header = () => {

    return (
        <>
        <Navigation/>
        <Banner/>
        </>
    )

}

export default Header;