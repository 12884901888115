
import { Container, Row, Col, ProgressBar } from 'react-bootstrap';
import './RevealSection.css'; // Import CSS file for styling
import FullLengthCard from '../FullLengthCard/FullLengthCard';




const RevealSection = () => {

const video = "https://www.youtube.com/embed/MJG9S36OfQg"   



  return (
    <div>
      <Container fluid>
        {/* Centered Heading */}
        <Row className="justify-content-center">
          <Col xs="auto">
            <h1 className="section-heading">You Named Your Škoda!</h1>
          </Col>
        </Row>


        <Row className="justify-content-center">
          <Col >
            <FullLengthCard content={<div> Thank you all for a tremendous response to the #NameYourSkoda campaign. From 200,000+ entries, we have finally found the name of the all-new Compact SUV. And, it's called... Škoda Kylaq. <br/> Watch the video to find out the story behind the name! </div>}/>
          </Col>
          </Row>


        {/* Video Player */}
        <Row>
          <Col>
            {/* Video Player - YouTube embed example */}
            <div className="video-container">
              <iframe
                title='video_player'
                width="100%"
                height="100%"
                src={video}
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            </div>
          </Col>
        </Row>
 {/* Horizontal Scrollable List of Thumbnails */}




       </Container>
       </div>
  );
};

export default RevealSection;
