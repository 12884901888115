
import { Container, Row, Col, Badge } from 'react-bootstrap';
import './PragueSection.css'; // Import CSS file for styling
import FullLengthCard from '../FullLengthCard/FullLengthCard';
import { FaFacebook, FaInstagram, FaXTwitter, FaYoutube } from 'react-icons/fa6';

import { colors } from '../../colors.js';




const PragueSection = () => {

const video = "https://www.youtube.com/embed/aODdhXIPITE"   

const names = [
   {info: 'jaydeepsarahiya', type: 'ig', name: 'Jaydeep Sarahiya'},
   {info: 'varun_solotraveller', type: 'ig', name: 'Varun Vasisht'},
   {info: 'saurabh.tandon.311', type: 'fb', name: 'Saurabh Tandon'},
   {info: 'elainesue.baptista', type: 'fb', name: 'Elaine B'},
   {info: 'santhoshjoghee', type: 'x', name: 'Santhosh Joghee'},
   {info: 'AkileshNrn', type: 'x', name: 'Akilesh Srikantaiah'},
   {info: 'Hyderabad', type: 'website', name: 'Charan'},
   {info: 'Kottayam', type: 'website', name: 'Rajesh Sudhakaran'},
   {info: 'Chennai', type: 'website', name: 'Arcchana Chalavadi'},
   {info: 'Pune', type: 'website', name: 'Priyanka Chinchorkar'},
]



  return (
    <div>
      <Container fluid>
        {/* Centered Heading */}
        <Row className="justify-content-center">
          <Col xs="auto">
            <h1 className="section-heading">Next destination: Prague!</h1>
          </Col>
        </Row>


        <Row className="justify-content-center">
          <Col>
            <FullLengthCard content={<div> 
              Here are our 10 lucky winners who have won an incredible trip with Škoda to Prague! We cannot wait for the exciting journey to begin. Big, Big congratulations to all!

              <Container fluid style={{marginTop:'20px'}}>
              <Row>
        {names.map((obj, index) => (
          <Col xs={12} sm={12} md={6} lg={6} xl={6} key={index}>
            <Badge 
              pill 
              className='my-2 w-100 d-flex align-items-center justify-content-center badge-name'
              style={{ height:'60px', textAlign:'center', fontSize:'1rem', }}
            >
    
              <div>
              {obj.name}
             <br/>
             
             {
            (() => {
              switch (obj.type) {
                case 'x':
                  return <span style={{fontSize:'0.75rem', textAlign:'center'}}>  <FaXTwitter/>  {obj.info} </span>
                case 'fb':
                  return <span style={{fontSize:'0.75rem', textAlign:'center'}}>  <FaFacebook/>  {obj.info} </span>
                case 'ig':
                  return <span style={{fontSize:'0.75rem', textAlign:'center'}}>  <FaInstagram/>  {obj.info} </span>
                default:
                  return <span style={{fontSize:'0.75rem', textAlign:'center'}}>   {obj.info} </span>
              }
            })()
          }
                 
              
              </div>
          
          
            </Badge>
          </Col>
        ))}
      </Row>
              </Container>

               </div>}/>
          </Col>
          </Row>






       </Container>
       </div>
  );
};

export default PragueSection;
