import './App.css';
import { useState } from 'react';
import FullLengthCard from './components/FullLengthCard/FullLengthCard';
import FullLengthCardTwo from './components/FullLengthCard/FullLengthCardTwo';
import Header from './components/Header/Header';
import 'bootstrap/dist/css/bootstrap.min.css';
import StepsSection from './components/StepsSection/StepsSection';
import VideoSection from './components/VideoSection/VideoSection';
import Footer from './components/Footer/Footer';
import EntrySection from './components/Entry Section/EntrySection';
import ThankYouCard from './components/FullLengthCard/ThankYouCard';
import { Routes, Route } from "react-router-dom";
import Terms from './components/Terms/Terms';
import VoteExisting from './components/VoteExisting/VoteExisting';
import RevealSection from './components/RevealSection/RevealSection';
import WinnerSection from './components/WinnerSection/WinnerSection';
import PragueSection from './components/PragueSection/PragueSection';

function App() {
  const [entrySuccessfullySubmitted, setEntrySuccessfullySubmitted] = useState(false);
  const [upvotedNames, setUpvotedNames] = useState([]);

  return (
    <>
    <div className='App'>

    <Header/>
    <div className='main-content'>
    <Routes> 
    <Route index element={
     <>
    <RevealSection/>
    <WinnerSection/>
    <PragueSection/>
   
    {/* {!entrySuccessfullySubmitted && <FullLengthCard/>}
    {!entrySuccessfullySubmitted && <VoteExisting setUpvotedNames={setUpvotedNames}/>}
    {!entrySuccessfullySubmitted && <FullLengthCardTwo/>}
    {!entrySuccessfullySubmitted && <StepsSection/>} */}

    {/* { <VideoSection/>} */}
    {/* {!entrySuccessfullySubmitted && <EntrySection upvotedNames={upvotedNames} onSuccess={setEntrySuccessfullySubmitted}/>}  */}
    </> 
    }/>
    <Route path='tncs' element={
     <Terms/>
    }/>
  </Routes>
  </div>
    <Footer/>
    </div>
    </>
  );
}

export default App;
